import { createWebHistory, createRouter } from "vue-router";
import store from "@/store";

import Login from "../views/Login.vue";
import Home from "../views/Home.vue";

import ForgotPassword from "../components/ForgotPassword.vue";
import ResetPassword from "../components/ResetPassword.vue";
import ValidateToken from "../components/ValidateToken.vue";

// APP VIEWS
import AppView from "@/views/App/AppView.vue";
import BlogView from "@/views/App/Blog/BlogView.vue";
import CoursesView from "@/views/App/Courses/CoursesView.vue";
import SingleCourseView from "@/views/App/Courses/SingleCourseView.vue";
import ForumsView from "@/views/App/Forums/ForumsView.vue";
import Settings from "@/views/App/Settings.vue";

// USERS VIEWS
import UsersView from "../views/Users/UsersView.vue";

// ACCOUNT VIEWS
import AccountView from "@/views/Account/AccountView.vue";
import ProfileView from "@/views/Account/ProfileView.vue";

// MEMBERSHIP VIEWS
import MembershipView from "@/views/Membership/MembershipView.vue";
import MyTeamsView from "@/views/Membership/MyTeamsView.vue";
import SingleTeamView from "@/views/Membership/SingleTeamView.vue";
import MySubscriptionView from "@/views/Membership/MySubscriptionView.vue";
import MembersView from "@/views/Membership/MembersView.vue";
import MemberSingleView from "@/views/Membership/MemberSingleView.vue";
import MemberReferralView from "@/views/Membership/MemberReferralView";
import RemittancesView from "@/views/Membership/RemittancesView.vue";
import SingleRemittanceView from "@/views/Membership/SingleRemittanceView.vue";
import BankView from "@/views/Membership/BankView.vue";

// PAYMENTS VIEWS
import ConfirmMemberPaymentView from "../views/Payments/ConfirmMemberPaymentView.vue";
import SuccessPaymentView from "@/views/Payments/SuccessPaymentView.vue";
import FailurePaymentView from "@/views/Payments/FailurePaymentView.vue";
import PaymentsView from "@/views/Payments/PaymentsView.vue";
import PaymentPaycometView from "@/views/Payments/PaymentPaycometView.vue";

// LEGAL VIEWS
import LegalView from "@/views/Legal/LegalView.vue";
import TermsAndConditions from "@/views/Legal/TermsAndConditionsView.vue";
import PrivacyPolicy from "@/views/Legal/PrivacyPolicyView";
import LegalWarning from "@/views/Legal/LegalWarningView";

// API Services
import UserService from "../services/user.service";

// User Data
async function getCurrentUser() {
	var response = await UserService.getCurrentUser();
	return response;
}

const routes = [
	// Login Page
	{
		path: "/login",
		name: "Login",
		component: Login,
	},

	// Home Page
	{
		path: "/",
		name: "Home",
		component: Home,
	},

	// Forgot Password Page
	{
		path: "/forgot-password",
		name: "ForgotPassword",
		component: ForgotPassword,
	},

	// Validate Token Page
	{
		path: "/validate-token/",
		name: "ValidateToken",
		component: ValidateToken,
	},

	// Reset Password Page
	{
		path: "/reset-password/",
		name: "ResetPassword",
		component: ResetPassword,
	},

	// Legal Policies Pages
	{
		path: "/legal",
		component: LegalView,
		redirect: { name: "LegalWarning" },
		children: [
			{
				path: "terms-and-conditions",
				component: TermsAndConditions,
				name: "TermsAndConditions",
			},
			{
				path: "privacy-policy",
				component: PrivacyPolicy,
				name: "PrivacyPolicy",
			},
			{
				path: "legal-warning",
				component: LegalWarning,
				name: "LegalWarning",
			},
		],
	},

	// Users Pages
	{
		path: "/users",
		name: "UsersView",
		component: UsersView,
		beforeEnter: async (to, from, next) => {
			const userData = store.state.user.data;
			if (userData.role == "superadmin" || userData.role == "admin") {
				next();
			} else {
				next("/login");
			}
		},
	},

	// Account Pages
	{
		path: "/account",
		name: "AccountView",
		component: AccountView,
		children: [
			{
				path: "profile",
				component: ProfileView,
				name: "ProfileView",
			},
		],
	},

	// App Pages
	{
		path: "/app",
		component: AppView,
		redirect: { name: "BlogView" },
		beforeEnter: async (to, from, next) => {
			const userData = store.state.user.data;
			if (userData.role == "superadmin" || userData.role == "admin") {
				next();
			} else {
				next("/login");
			}
		},
		children: [
			{
				path: "blog",
				component: BlogView,
				name: "BlogView",
			},
			{
				path: "courses",
				name: "CoursesView",
				component: CoursesView,
			},
			{
				path: "courses/:courseUUID",
				name: "SingleCourseView",
				component: SingleCourseView,
			},
			{
				path: "forums",
				component: ForumsView,
				name: "ForumsView",
			},
		],
	},

	// Membership Pages
	{
		path: "/membership",
		component: MembershipView,
		redirect: { name: "MyTeamsView" },
		children: [
			{
				path: "teams",
				name: "MyTeamsView",
				component: MyTeamsView,
			},
			{
				path: "teams/:teamUUID",
				name: "SingleTeamView",
				component: SingleTeamView,
			},

			{
				path: "members",
				name: "MembersView",
				component: MembersView,
			},

			{
				path: "members/:memberUUID",
				name: "MemberSingleView",
				component: MemberSingleView,
			},

			{
				path: "subscription",
				name: "MySubscriptionView",
				component: MySubscriptionView,
			},
			{
				path: "remittances",
				name: "RemittancesView",
				component: RemittancesView,
			},
			{
				path: "remittances/:remittanceUUID",
				name: "SingleRemittanceView",
				component: SingleRemittanceView,
			},
			{
				path: "bank",
				name: "BankView",
				component: BankView,
			},
		],
	},

	// Payments Pages
	{
		path: "/payments",
		component: PaymentsView,
		redirect: { name: "PaymentsView" },
		children: [
			{
				path: "success",
				name: "SuccessPaymentView",
				component: SuccessPaymentView,
			},
			{
				path: "failure",
				name: "FailurePaymentView",
				component: FailurePaymentView,
			},
			{
				path: "paycomet/:paymentID",
				name: "PaymentPaycometView",
				component: PaymentPaycometView,
			},
		],
	},

	{
		path: "/payment/member/confirm/:memberUUID",
		name: "ConfirmMemberPaymentView",
		component: ConfirmMemberPaymentView,
	},
	{
		path: "/member/add/referral/:referral_code",
		name: "MemberReferralView",
		component: MemberReferralView,
	},

	{
		path: "/settings",
		name: "Settings",
		component: Settings,
		beforeEnter: async (to, from, next) => {
			const userData = await getCurrentUser();

			if (userData.role == "superadmin" || userData.role == "admin") {
				next();
			} else {
				next("/login");
			}
		},
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});
// console.log(router)
export default router;

router.beforeEach((to, from, next) => {
	const publicPages = [
		"Login",
		"ForgotPassword",
		"ValidateToken",
		"ResetPassword",
		"ConfirmMemberPaymentView",
		"SuccessPaymentView",
		"PaymentPaycometView",
		"TermsAndConditions",
		"PrivacyPolicy",
		"LegalWarning",
		"MemberReferralView",
	];
	const authRequired = !publicPages.includes(to.name);
	const loggedIn = localStorage.getItem("auth");

	// trying to access a restricted page + not logged in
	// redirect to login page
	if (authRequired && !loggedIn) {
		next("/login");
	} else {
		next();
	}
});
